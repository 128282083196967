import { Button, Col, Form, Input, Row, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import storageService from '../../../services/storageService';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../../actions/notificationAction';
import httpService from '../../../services/httpService';

const { Option } = Select;

const AddVideo = () => {
    const [categories, setCategories] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [videos, setVideos] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        title: undefined,
        description: undefined,
        videoUrl: undefined,
        categories: [],
    });

    const dispatch = useDispatch();

    const handleDelete = (id) => {
        setDeleteLoading(true);
        httpService.delete(`video/deleteVideo/${id}`, storageService.getToken())
            .then(response => {
                dispatch(showNotification({
                    type: 'success',
                    message: 'Video Silindi'
                }));
                setVideos(videos.filter(video => video.id !== id));
            })
            .catch(error => {
                dispatch(showNotification({
                    type: 'error',
                    message: "Video silinirken bir hata oluştu."
                }));
            })
            .finally(() => {
                setDeleteLoading(false);
            });
    };

    const handleSubmit = () => {
        setLoading(true);
        httpService.post("video/addVideo", formData, storageService.getToken())
            .then(response => {
                dispatch(showNotification({
                    type: 'success',
                    message: "Video başarıyla eklendi."
                }));
                setVideos([response.data.data, ...videos]);
                setFormData({
                    title: undefined,
                    description: undefined,
                    videoUrl: undefined,
                    categories: [],
                });
                setTotalRecords(totalRecords + 1);
            })
            .catch(error => {
                dispatch(showNotification({
                    type: 'error',
                    message: "Video eklenirken bir hata oluştu."
                }));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        httpService.get(`category/getCategories`, storageService.getToken())
            .then(response => {
                setCategories(response.data.data);
            })
            .catch(error => {
                dispatch(showNotification({
                    type: 'error',
                    message: "Kategoriler yüklenirken bir hata oluştu."
                }));
            })
            .finally(() => {
                setLoading(false);
            });

        httpService.get(`video/getVideos?pageNumber=${currentPage}&pageSize=${pageSize}`, storageService.getToken())
            .then(response => {
                setVideos(response.data.data);
                setTotalRecords(response.data.pager.totalRecords);
                setCurrentPage(response.data.pager.pageNumber);
            })
            .catch(error => {
                dispatch(showNotification({
                    type: 'error',
                    message: "Videolar yüklenirken bir hata oluştu."
                }));
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handlePageChange = (page, pageSize) => {
        setLoading(true);
        httpService.get(`video/getVideos?pageNumber=${page}&pageSize=${pageSize}`, storageService.getToken())
            .then(response => {
                setVideos(response.data.data);
                setTotalRecords(response.data.pager.totalRecords);
                setCurrentPage(response.data.pager.pageNumber);
            })
            .catch(error => {
                dispatch(showNotification({
                    type: 'error',
                    message: "Videolar yüklenirken bir hata oluştu."
                }));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    return (
        <Row justify={"center"}>
            <Col span={24}>
                <Form layout="vertical" onFinish={handleSubmit}>
                    <Form.Item label="Video Başlığı" required>
                        <Input
                            value={formData.title}
                            onChange={(e) => handleChange('title', e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item label="Video Açıklaması" required>
                        <Input.TextArea
                            value={formData.description}
                            onChange={(e) => handleChange('description', e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item label="Video Youtube URL" required>
                        <Input
                            value={formData.videoUrl}
                            onChange={(e) => handleChange('videoUrl', e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item label="Video Kategorileri" required>
                        <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            placeholder="Kategorileri seçin"
                            value={formData.categories}
                            onChange={(value) => handleChange('categories', value)}
                        >
                            {categories.map((category) => (
                                <Option key={category.id} value={category.id}>{category.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button loading={loading} className='bg-primary' type="primary" htmlType="submit">
                            Video Ekle
                        </Button>
                    </Form.Item>
                </Form>
            </Col>

            <Col span={20} className='justify-center flex'>
                <Table className='w-full' loading={loading} dataSource={videos} rowKey={"_id"} pagination={{
                    current: currentPage,
                    total: totalRecords,
                    hideOnSinglePage: false,
                    showTotal: (total, range) => `${total} videodan ${range[0]}-${range[1]} aralığı gösteriliyor`,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => {
                        setPageSize(pageSize);
                        handlePageChange(page, pageSize);
                    }
                }}>
                    <Table.Column title={"Video İsmi"} dataIndex={"title"} key={"title"} />
                    <Table.Column title={"Video Açıklaması"} dataIndex={"description"} key={"description"} />
                    <Table.Column title={"Video URL"} dataIndex={"videoUrl"} key={"videoUrl"} />
                    <Table.Column
                        title={"İşlemler"}
                        key={"actions"}
                        render={(text, record) => (
                            <Button
                                type='primary'
                                loading={deleteLoading}
                                className='bg-primary'
                                onClick={() => handleDelete(record.id)}
                            >
                                Sil
                            </Button>
                        )}
                    />
                </Table>
            </Col>
        </Row>
    );
};

export default AddVideo;