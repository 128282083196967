import { Col, Image, Row } from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom';

const ContentContainer = () => {

  return (
    <Row className='min-h-screen z-10'>
      <Col sm={1} md={4}>
        {/* <img src={require("../assets/images/azi.png")} alt='logo' className='h-full object-cover' /> */}
      </Col>
      <Outlet />
      <Col sm={1} md={4}>
        {/* <img src={require("../assets/images/azi.png")} alt='logo' className='h-full object-cover' /> */}
      </Col>
    </Row>
  );
};

export default ContentContainer;;