import i18next from "i18next";
import Cookies from "js-cookie";

class StorageService {

    addToken = (token) => {
        Cookies.remove("token-azi", { secure: true });
        Cookies.set("token-azi", token, { secure: true });
    };

    removeToken = () => {
        Cookies.remove("token-azi", { secure: true });
    };

    getToken = () => {
        return Cookies.get("token-azi", { secure: true });
    };

    addRefreshToken = (refreshToken) => {
        Cookies.remove("refreshToken-azi", { secure: true });
        Cookies.set("refreshToken-azi", refreshToken, { secure: true });
        localStorage.setItem("refreshToken-azi", refreshToken);
    };

    removeRefreshToken = () => {
        Cookies.remove("refreshToken-azi", { secure: true });
    };

    getRefreshToken = () => {
        return Cookies.get("refreshToken-azi", { secure: true }) || localStorage.getItem("refreshToken-azi");
    };

    changeLanguage = (lng) => {
        i18next.changeLanguage(lng);
        localStorage.setItem("lng-azi", lng);
    };

    getLanguage = () => {
        const lng = localStorage.getItem("lng-azi");

        return lng ? lng : "tr";
    };

    getCurrentTheme = () => {
        const theme = localStorage.getItem("theme-azi");
        return theme ? theme : "dark";
    };

    setCurrentTheme = (theme) => {
        localStorage.setItem("theme-azi", theme);
    };

}

const storageService = new StorageService();

export default storageService;