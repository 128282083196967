import { Button, Input } from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom';
import storageService from '../../services/storageService';
import { getUserTypeByToken } from '../../services/userService';
import { useDispatch } from 'react-redux';
import { changeTheme } from "../../actions/themeAction";
import { Icon } from '@iconify/react';
import { UserType } from '../../models/enums/UserType';


const Navbar = () => {

    const dispatch = useDispatch();

    const theme = storageService.getCurrentTheme();

    const handleChangeTheme = () => {
        storageService.setCurrentTheme(theme === "light" ? "dark" : "light");
        dispatch(changeTheme(theme === "light" ? "dark" : "light"));
    };
    const navbarButtonStyles = {
        active: "block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent",
        passive: "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
    };

    return (
        <>
            <nav className="bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <a href="/" className="flex items-center space-x-3">
                        <img src={require("../../assets/images/new_azi.png")} className="h-10 rounded-full" alt="Flowbite Logo" />
                        <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Ahir Zaman İnsanları</span>
                    </a>
                    <div className='flex md:hidden flex-row items-center justify-center'>
                        <Input className='w-24 sm:w-52 h-10 p-2 rounded-lg rounded-r-none dark:bg-gray-700 dark:border-gray-500 dark:text-white dark:placeholder-gray-400 duration-500' type='text' placeholder='Arama Yap' />
                        <Button type='primary' className='h-10 rounded-l-none bg-primary hover:!bg-primary-hover'>Ara</Button>
                    </div>
                    <button data-collapse-toggle="navbar-dropdown" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-dropdown" aria-expanded="false">
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                    <div className="hidden w-full md:block md:w-auto">
                        <ul className="flex flex-col items-center font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                            <li className='sm:hidden md:flex flex-row items-center justify-center flex-grow'>
                                <Input className='md:flex-grow lg:w-3/4 h-10 p-2 rounded-lg rounded-r-none dark:bg-gray-700 dark:border-gray-500 dark:text-white dark:placeholder-gray-400 duration-500' type='text' placeholder='Arama Yap' />
                                <Button type='primary' className='h-10 rounded-l-none bg-primary hover:!bg-primary-hover'>Ara</Button>
                            </li>
                            <li>
                                <a href="/" className={window.location.pathname === "/" ? navbarButtonStyles.active : navbarButtonStyles.passive}>Ana Sayfa</a>
                            </li>
                            <li>
                                <a href="/videos" className={window.location.pathname.startsWith("/videos") ? navbarButtonStyles.active : navbarButtonStyles.passive}>Videolar</a>
                            </li>
                            <li>
                                <a href="/documents" className={window.location.pathname.startsWith("/documents") ? navbarButtonStyles.active : navbarButtonStyles.passive}>Dosyalar</a>
                            </li>
                            <li>
                                <a href="/photos" className={window.location.pathname.startsWith("/photos") ? navbarButtonStyles.active : navbarButtonStyles.passive}>Fotoğraflar</a>
                            </li>
                            <li>
                                <a href="/songs" className={window.location.pathname.startsWith("/songs") ? navbarButtonStyles.active : navbarButtonStyles.passive}>Sesler</a>
                            </li>
                            {getUserTypeByToken() === UserType.Admin && (
                                <li>
                                    <a href="/admin" className={window.location.pathname.startsWith("/admin") ? navbarButtonStyles.active : navbarButtonStyles.passive}>Admin</a>
                                </li>
                            )}
                            <li>
                                <Button type="text" shape="circle" className='flex items-center justify-center' onClick={handleChangeTheme}>
                                    {theme === "light" ? (
                                        <Icon
                                            icon="iconamoon:mode-dark-light"
                                            style={{
                                                fontSize: "1.5rem",
                                                color: "var(--text-secondary)",
                                            }}
                                        />
                                    ) : (
                                        <Icon
                                            icon="entypo:light-up"
                                            style={{ fontSize: "1.5rem" }}
                                            className="text-gray-200"
                                        />
                                    )}
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <Outlet />
        </>

    );
};

export default Navbar;