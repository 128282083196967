import { combineReducers } from "redux";
import notificationReducer from "./notificationReducer";
import userReducer from "./userReducer";
import themeReducer from "./themeReducer";

const rootReducer = combineReducers({
    notification: notificationReducer,
    currentUser: userReducer,
    theme: themeReducer
});

export default rootReducer;