import storageService from "./storageService";
import { jwtDecode } from "jwt-decode";

export const getUserIdByToken = () => {
  const token = storageService.getToken();
  if (!token || token === 'undefined') return undefined;
  return jwtDecode(token).UserId;
};

export const getEmailByToken = () => {
  const token = storageService.getToken();
  if (!token || token === 'undefined') return undefined;
  return jwtDecode(token).Email;
};

export const getNameSurnameByToken = () => {
  const token = storageService.getToken();
  if (!token || token === 'undefined') return undefined;
  return jwtDecode(token).Name + " " + jwtDecode(token).Surname;
};

export const getNameByToken = () => {
  const token = storageService.getToken();
  if (!token || token === 'undefined') return undefined;
  return jwtDecode(token).Name;
};

export const getSurnameByToken = () => {
  const token = storageService.getToken();
  if (!token || token === 'undefined') return undefined;
  return jwtDecode(token).Surname;
};


export const getUserTypeByToken = () => {
  const token = storageService.getToken();
  if (!token || token === 'undefined') return undefined;
  return parseInt(jwtDecode(token).UserType);
};
