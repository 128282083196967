import { CHANGE_THEME } from "../actions/themeAction";
import storageService from "../services/storageService";

const initialState = {
    theme: storageService.getCurrentTheme(),
};

const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_THEME:
            return {
                ...state,
                theme: action.payload,
            };

        default:
            return state;
    }
};

export default themeReducer;
