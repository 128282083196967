import { Button, Col, Row } from 'antd';
import React, { useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import AuthContext from '../../auth/AuthContext';

const AdminLayout = () => {
    const { onLogout } = useContext(AuthContext);
    const navigate = useNavigate();


    return (
        <Row className="p-4 md:p-8" justify={"center"} align={"top"}>
            <Col lg={3} md={5} sm={7}>
                <Col span={24} className="mb-4">
                    <Button type="primary" className='bg-primary w-full' onClick={() => navigate("/admin/addDocument")} >
                        Dosya Ekle
                    </Button>
                </Col>
                <Col span={24} className="mb-4">
                    <Button type="primary" className='bg-primary w-full' onClick={() => navigate("/admin/addPhoto")} >
                        Fotoğraf Ekle
                    </Button>
                </Col>
                <Col span={24} className="mb-4">
                    <Button type="primary" className='bg-primary w-full' onClick={() => navigate("/admin/addSong")} >
                        Ses Ekle
                    </Button>
                </Col>
                <Col span={24} className="mb-4">
                    <Button type="primary" className='bg-primary w-full' onClick={() => navigate("/admin/addVideo")} >
                        Video Ekle
                    </Button>
                </Col>
                <Col span={24} className="mb-4">
                    <Button type="primary" className='bg-primary w-full' onClick={() => navigate("/admin/addCategory")} >
                        Kategori Ekle
                    </Button>
                </Col>
                <Col span={24} className="mb-4">
                    <Button type="primary" className='bg-primary w-full' onClick={() => onLogout()} >
                        Çıkış Yap
                    </Button>
                </Col>
            </Col>
            <Col span={1} />
            <Col lg={20} md={18} sm={16}>
                <Outlet />
            </Col>
        </Row>
    );
};

export default AdminLayout;