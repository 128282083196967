import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import httpService from '../../services/httpService';
import Loader from '../../components/Loader/Loader';

const Videos = () => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);

    function removeConvertEmbed(url) {

        const urlObj = new URL(url);
        const params = urlObj.searchParams;
        params.delete('ab_channel');

        return urlObj.toString().replace('watch?v=', 'embed/');
    }

    useEffect(() => {
        httpService.get('video/getVideos?pageSize=100&pageNumber=1')
            .then((response) => {
                setVideos(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    return (
        <>
            <Col sm={22} md={16} className='flex flex-col w-full items-center justify-center mx-auto mt-10 border-2 border-solid p-4 border-zinc-300 bg-zinc-100 rounded-xl dark:border-zinc-500 dark:bg-zinc-600'>
                <h3 className='text-3xl font-semibold mb-3 dark:text-white'>Videolar</h3>
                <Row className='items-center w-full'>
                    {loading ?
                        <Loader bgColor={'#52525b'} />
                        :
                        videos.map((video, index) => (
                            <Col key={index} sm={24} md={12} className='flex flex-col items-center justify-center p-2 h-56'>
                                <iframe className='w-full h-full' src={removeConvertEmbed(video.videoUrl)} title={video.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            </Col>
                        ))
                    }
                </Row>
            </Col>
        </>
    );
};

export default Videos;