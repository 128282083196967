import { Col, Image, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import httpService from '../../services/httpService';
import Loader from '../../components/Loader/Loader';

const Photos = () => {
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        httpService.get('photo/getPhotos?pageSize=100&pageNumber=1')
            .then((response) => {
                setPhotos(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    return (
        <>
            <Col sm={22} md={16} className='flex flex-col items-center justify-center mx-auto mt-10 border-2 border-solid p-4 border-zinc-300 bg-zinc-100 rounded-xl dark:border-zinc-500 dark:bg-zinc-600'>
                <h3 className='text-3xl font-semibold mb-3 dark:text-white'>Fotoğraflar</h3>
                <Row className='items-center w-full'>
                    {loading ?
                        <Loader bgColor={'#52525b'} />
                        :
                        photos.map((photo, index) => (
                            <Col key={index} sm={24} md={12} className='flex flex-col items-center justify-center p-2'>
                                <Image className='min-w-full w-full max-w-full rounded-lg mb-2 object-contain' src={photo.fileUrl} />
                                {/* <div className='w-full h-40 bg-zinc-300 dark:bg-zinc-500 rounded-lg mb-2'></div> */}
                                <h4 className='text-lg font-semibold dark:text-white'>{photo.title}</h4>
                            </Col>
                        ))
                    }
                </Row>
            </Col>
        </>
    );
};

export default Photos;