import React, { useContext, useState } from 'react';
import useAuth from '../../auth/useAuth';
import AuthContext from '../../auth/AuthContext';
import { Navigate, useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import httpService from '../../services/httpService';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../actions/notificationAction';

const AdminLogin = () => {
    const { isAuthenticated } = useAuth();
    const [loading, setLoading] = useState(false);
    const { onLogin } = useContext(AuthContext);

    const dispatch = useDispatch();

    const signIn = (form) => {
        setLoading(true);
        httpService.post('auth/login', form, null)
            .then(response => {
                onLogin(response.data.data.accessToken, response.data.data.refreshToken);
            })
            .catch(error => {
                if (error.response.status === 404) {
                    dispatch(showNotification({ type: 'error', message: 'Giriş Başarısız', description: "E-posta veya şifre hatalı. Lütfen tekrar deneyin." }));
                } else {
                    dispatch(showNotification({ type: 'error', message: 'Giriş Başarısız', description: "Bir hata oluştu. Lütfen tekrar deneyin." }));
                }
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return isAuthenticated ? (
        <Navigate to="/admin" />) : (
        <div className="flex justify-center items-center flex-grow">
            <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={signIn}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className="w-full max-w-xs"
            >
                <Form.Item
                    label="E-posta"
                    name="email"
                    labelAlign='left'
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    rules={[{ required: true, message: 'Lütfen Email giriniz' }]}
                >
                    <Input className='dark:bg-gray-700 dark:border-gray-500 dark:placeholder-gray-400' />
                </Form.Item>

                <Form.Item
                    label="Şifre"
                    name="password"
                    labelAlign='left'
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    rules={[{ required: true, message: 'Lütfen Şifre Giriniz' }]}
                >
                    <Input.Password className='dark:bg-gray-700 dark:border-gray-500 dark:placeholder-gray-400' />
                </Form.Item>

                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit" className="w-full bg-primary">
                        Giriş Yap
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AdminLogin;