import React from 'react';
import { Spin } from 'antd';
const Loader = (props) => {
    return (
        <>
            <Spin
                className={`flex items-center justify-center sticky top-0 min-h-screen w-full h-full, overflow-hidden bg-[${props.bgColor ? props.bgColor : '#212121'}] z-[99999]`}
                size="large"
            />
        </>
    );
};

export default Loader;