import { SET_CURRENT_USER } from "../actions/userAction";

const initialState = {
   id: "",
   email: "",
   name: "",
   surname: "",
};

const userReducer = (state = initialState, action) => {
   switch (action.type) {
      case SET_CURRENT_USER:
         return {
            ...state,
            id: action.payload.id,
            email: action.payload.email,
            name: action.payload.name,
            surname: action.payload.surname
         };
      default:
         return state;
   }
};

export default userReducer;