import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { lazy, useEffect, Suspense } from 'react';
import { ConfigProvider, notification } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "react-error-boundary";
import { getAntdLocale } from "./helpers/helperFunctions";

import Error from "./pages/Error";
import Loader from "./components/Loader/Loader";
import Navbar from "./components/Navbar/Navbar";
import PrivateRoute from "./auth/PrivateRoute";
import AuthProvider from "./auth/AuthProvider";
import AdminHome from "./pages/Admin/AdminHome";
import ContentContainer from "./containers/ContentContainer";
import Photos from "./pages/Photo/Photos";
import Songs from "./pages/Song/Songs";
import Videos from "./pages/Video/Videos";
import Documents from "./pages/Document/Documents";
import AdminLogin from "./pages/Admin/AdminLogin";
import AdminLayout from "./pages/Admin/AdminLayout";
import AddDocument from "./pages/Admin/Document/AddDocument";
import AddVideo from "./pages/Admin/Video/AddVideo";
import AddCategory from "./pages/Admin/Category/AddCategory";
import AddPhoto from "./pages/Admin/Photo/AddPhoto";
import AddSong from "./pages/Admin/Song/AddSong";



const Home = lazy(() => import("./pages/Home"));


function App() {

  const [api, notificationHolder] = notification.useNotification();
  const notificationState = useSelector((state) => state.notification);

  const currentTheme = useSelector((state) => state.theme).theme;

  const { t, i18n } = useTranslation();


  useEffect(() => {
    if (notificationState.type !== undefined && notificationState.message !== "") {
      api[notificationState.type]({
        message: notificationState.message,
        description: notificationState.description,
        placement: "bottomRight",
      });
    }
  }, [notificationState]);

  return (
    <div className={`${currentTheme} ${currentTheme === "dark" ? "bg-[#212121]" : "bg-tertiary"} min-h-screen flex flex-col pb-5`}>
      {notificationHolder}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#fa953f",
            colorBgBase: currentTheme === "dark" ? "#333" : "#fff",
            colorTextBase: currentTheme === "dark" ? "#fff" : "#333",
          },
          components: {
          },
        }}
        locale={getAntdLocale(i18n.language)}
      >
        <ErrorBoundary FallbackComponent={Error}>
          <Suspense fallback={<Loader />}>
            <Router>
              <AuthProvider>
                <Routes>
                  <Route path="/" element={<Navbar />} >
                    <Route path="/" element={<ContentContainer />} >
                      <Route path="/" element={<Home />} />
                      <Route path="/videos" element={<Videos />} />
                      <Route path="/documents" element={<Documents />} />
                      <Route path="/photos" element={<Photos />} />
                      <Route path="/songs" element={<Songs />} />
                    </Route>
                    <Route path="/login" element={<AdminLogin />} />
                    <Route path="/admin" element={<PrivateRoute><AdminLayout /></PrivateRoute>} >
                      <Route path="" element={<AdminHome />} />
                      <Route path="addDocument" element={<AddDocument />} />
                      <Route path="addPhoto" element={<AddPhoto />} />
                      <Route path="addSong" element={<AddSong />} />
                      <Route path="addVideo" element={<AddVideo />} />
                      <Route path="addCategory" element={<AddCategory />} />
                    </Route>
                  </Route>
                </Routes>
              </AuthProvider>
            </Router>
          </Suspense>
        </ErrorBoundary>
      </ConfigProvider>
    </div>
  );
}

export default App;
