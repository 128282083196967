import { Button, Col, Result, Row } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Error = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = t("ERROR.PAGE_TITLE");
  }, [i18n.language]);

  return (
    <Row style={{ height: "100vh", width: "100vw", marginTop: "10vh" }}>
      <Col offset={8} span={8}>
        <Result
          status="500"
          title="500"
          subTitle={"Bir Hata Oluştu"}
          extra={<Button type="primary" href="/">{"Ana Sayfa"}</Button>}
        />
      </Col>
    </Row>
  );
};

export default Error;
