import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from './useAuth';

const PrivateRoute = ({ children }) => {
    const { isAuthenticated } = useAuth();
    const location = useLocation();
    return (
        <>
            {isAuthenticated ? (
                children
            ) : (
                <Navigate to={"/login"} replace state={{ location }} />
            )}
        </>
    );
};

export default PrivateRoute;