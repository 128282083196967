import { Button, Col, Form, Input, Row, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import httpService from '../../../services/httpService';
import storageService from '../../../services/storageService';
import { showNotification } from '../../../actions/notificationAction';
import { CheckCircleOutlined, FileAddOutlined } from '@ant-design/icons';

const { Option } = Select;

const AddDocument = () => {
    const [form] = Form.useForm();
    const [categories, setCategories] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(true);
    const [fileName, setFileName] = useState(null);


    const dispatch = useDispatch();

    const handleDelete = (id) => {
        setDeleteLoading(true);
        httpService.delete(`document/deleteDocument/${id}`, storageService.getToken())
            .then(response => {
                dispatch(showNotification({
                    type: 'success',
                    message: 'Dosya Silindi'
                }));
                setDocuments(documents.filter(document => document.id !== id));
            })
            .catch(error => {
                dispatch(showNotification({
                    type: 'error',
                    message: "Dosya silinirken bir hata oluştu."
                }));
            })
            .finally(() => {
                setDeleteLoading(false);
            });
    };

    const resetFields = () => {
        form.resetFields();
        setFileName(null);
    };

    const handleFileUpload = (e) => {
        form.setFieldValue("file", e.target.files[0]);
        setFileName(e?.target?.files[0]?.name);
    };

    const onFinish = (form) => {
        setLoading(true);
        const formData = new FormData();
        try {
            formData.append("title", form.title);
            formData.append("description", form.description);
            formData.append("file", form.file);
            form.categories.forEach(element => {
                formData.append("categories", element);
            });
        } catch (error) {
            dispatch(showNotification({ type: 'error', message: "Dosya eklenirken bir hata oluştu." }));
            console.log(error);
            setLoading(false);
        }

        httpService.post("document/addDocument", formData, storageService.getToken())
            .then(response => {
                dispatch(showNotification({
                    type: 'success',
                    message: "Dosya başarıyla eklendi."
                }));
                setDocuments([response.data.data, ...documents]);
                setTotalRecords(totalRecords + 1);
                resetFields();
            })
            .catch(error => {
                dispatch(showNotification({
                    type: 'error',
                    message: "Dosya eklenirken bir hata oluştu."
                }));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        httpService.get(`category/getCategories`, storageService.getToken())
            .then(response => {
                setCategories(response.data.data);
            })
            .catch(error => {
                dispatch(showNotification({
                    type: 'error',
                    message: "Kategoriler yüklenirken bir hata oluştu."
                }));
            })
            .finally(() => {
                setLoading(false);
            });

        httpService.get(`document/getDocuments?pageNumber=${currentPage}&pageSize=${pageSize}`, storageService.getToken())
            .then(response => {
                setDocuments(response.data.data);
                setTotalRecords(response.data.pager.totalRecords);
                setCurrentPage(response.data.pager.pageNumber);
            })
            .catch(error => {
                dispatch(showNotification({
                    type: 'error',
                    message: "Dosyalar yüklenirken bir hata oluştu."
                }));
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handlePageChange = (page, pageSize) => {
        setLoading(true);
        httpService.get(`document/getDocuments?pageNumber=${page}&pageSize=${pageSize}`, storageService.getToken())
            .then(response => {
                setDocuments(response.data.data);
                setTotalRecords(response.data.pager.totalRecords);
                setCurrentPage(response.data.pager.pageNumber);
            })
            .catch(error => {
                dispatch(showNotification({
                    type: 'error',
                    message: "Dosyalar yüklenirken bir hata oluştu."
                }));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Row justify={"center"}>
            <Col span={24}>
                <Form
                    form={form}
                    name="documentForm"
                    onFinish={onFinish}
                    autoComplete="off"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 14 }}
                    layout="horizontal"
                    initialValues={{ remember: true }}
                    className="p-5"
                >
                    <Form.Item
                        label="Başlık"
                        name="title"
                        rules={[{ required: true, message: 'Lütfen bir başlık giriniz!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Açıklama"
                        name="description"
                        rules={[{ required: true, message: 'Lütfen bir açıklama giriniz!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <input
                        type="file"
                        onChange={handleFileUpload}
                        id="fileInput"
                        style={{ display: "none" }}
                    />
                    <Form.Item
                        label="Dosya"
                        name={"file"}
                        rules={[{ required: true, message: 'Lütfen dosya yükleyiniz' }]}
                    >
                        <Button
                            type="primary"
                            icon={fileName ? <CheckCircleOutlined style={{ color: "#5161ce" }} /> : <FileAddOutlined style={{ color: "#5161CE" }} />}
                            className='w-full bg-primary'
                            onClick={() => document.getElementById("fileInput").click()}
                        >
                            {fileName && fileName.length
                                ? fileName.length > 30
                                    ? fileName?.substring(0, 30) + "..."
                                    : fileName
                                : "Dosya Seç"}
                        </Button>
                    </Form.Item>
                    {/* <Form.Item
                        label="Belge URL"
                        name="fileUrl"
                        rules={[{ required: true, message: 'Lütfen bir belge URL\'si giriniz!' }]}
                    >
                        <Input />
                    </Form.Item> */}

                    <Form.Item
                        label="Kategoriler"
                        name="categories"
                        initialValue={[]}
                        rules={[{ required: false }]}
                    >
                        <Select mode="tags" style={{ width: '100%' }} placeholder="Kategorileri seçin veya yazın">
                            {categories.map((category) => (
                                <Option key={category.id} value={category.id}>{category.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
                        <Button className='bg-primary' type="primary" htmlType="submit">
                            Gönder
                        </Button>
                    </Form.Item>
                </Form>
            </Col>

            <Col span={20} className='justify-center flex'>
                <Table className='w-full' loading={loading} dataSource={documents} rowKey={"_id"} pagination={{
                    current: currentPage,
                    total: totalRecords,
                    hideOnSinglePage: false,
                    showTotal: (total, range) => `${total} dosyadan ${range[0]}-${range[1]} aralığı gösteriliyor`,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => {
                        setPageSize(pageSize);
                        handlePageChange(page, pageSize);
                    }
                }}>
                    <Table.Column title={"Dosya İsmi"} dataIndex={"title"} key={"title"} />
                    <Table.Column title={"Dosya Açıklaması"} dataIndex={"description"} key={"description"} />
                    <Table.Column title={"Dosya URL"} key={"fileUrl"}
                        render={(text, record) => (
                            <a href={record.fileUrl} target="_blank" rel="noreferrer">
                                Dosyayı Görüntüle
                            </a>
                        )}
                    />
                    <Table.Column
                        title={"İşlemler"}
                        key={"actions"}
                        render={(text, record) => (
                            <Button
                                type='primary'
                                loading={deleteLoading}
                                className='bg-primary'
                                onClick={() => handleDelete(record.id)}
                            >
                                Sil
                            </Button>
                        )}
                    />
                </Table>
            </Col>
        </Row>
    );
};

export default AddDocument;