import enUS from "antd/lib/locale/en_US";
import trTR from "antd/lib/locale/tr_TR";
import moment from "moment";
import dayjs from "dayjs";

export const utcToLocaleLangFormat = (utcFormat, lang) => {
  const date = new Date(utcFormat);
  let offset = new Date().getTimezoneOffset();
  date.setMinutes(date.getMinutes() - offset);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "UTC",
  };
  const formattedDate = date.toLocaleDateString(lang, options);
  return formattedDate.toString();
};

export const getAntdLocale = (lang) => {
  switch (lang) {
    case "en":
      return enUS;
    case "tr":
      return trTR;
    default:
      return trTR;
  }
};

export const convertGmtToLocal = (date) => {
  var offset = new Date().getTimezoneOffset();
  var localDate = moment.utc(date).add(-offset, "minutes");
  return localDate;
};

export const convertLocalToGmt = (date) => {
  var offset = new Date().getTimezoneOffset();
  var gmtDate = moment.utc(date.format('YYYY-MM-DDTHH:mm:ss[Z]')).add(offset, "minutes");
  return gmtDate;
};

export const convertApiDateToAntdDate = (date) => {
  return dayjs(convertGmtToLocal(date).toISOString(), "YYYY-MM-DD HH:mm");
};
