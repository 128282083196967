import { useContext } from "react";
import AuthContext from "./AuthContext";
import { getUserIdByToken } from "../services/userService";


const useAuth = () => {
    var { token } = useContext(AuthContext);
    var auth = getUserIdByToken(token);
    var isAuthenticated = false;
    if (auth) {
        isAuthenticated = auth ? true : false;
    }

    return { isAuthenticated };
};
export default useAuth;