import { Button, Col, Form, Input, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import httpService from '../../../services/httpService';
import storageService from '../../../services/storageService';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../../actions/notificationAction';

const AddCategory = () => {
    const [formData, setFormData] = useState({});
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const dispatch = useDispatch();

    const handleSubmit = () => {
        setLoading(true);
        httpService.post("category/addCategory", formData, storageService.getToken())
            .then(response => {
                dispatch(showNotification({
                    type: 'success',
                    message: 'Kategori Eklendi'
                }));
                setCategories([response.data.data, ...categories]);
                setFormData({});
            })
            .catch(error => {
                dispatch(showNotification({
                    type: 'error',
                    message: "Kategori eklenirken bir hata oluştu."
                }));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleDelete = (id) => {
        setDeleteLoading(true);
        httpService.delete(`category/deleteCategory/${id}`, storageService.getToken())
            .then(response => {
                dispatch(showNotification({
                    type: 'success',
                    message: 'Kategori Silindi'
                }));
                setCategories(categories.filter(category => category.id !== id));
            })
            .catch(error => {
                dispatch(showNotification({
                    type: 'error',
                    message: error.response.data.message
                }));
            })
            .finally(() => {
                setDeleteLoading(false);
            });
    };

    useEffect(() => {
        httpService.get("category/getCategories", storageService.getToken())
            .then(response => {
                setCategories(response.data.data);
            })
            .catch(error => {
                dispatch(showNotification({
                    type: 'error',
                    message: "Kategoriler yüklenirken bir hata oluştu."
                }));
            });
    }, []);


    return (
        <Row justify={"center"}>
            <Col span={24} className='justify-center flex'>
                <Form layout="vertical" onFinish={handleSubmit}>
                    <Form.Item label="Kategori İsmi" name={"name"} required>
                        <Input
                            onChange={(e) => handleChange('name', e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button className='bg-primary' type="primary" htmlType="submit" loading={loading}>
                            Ekle
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
            <Col span={16} className='justify-center flex'>
                <Table className='w-full' dataSource={categories} pagination={false} rowKey={"_id"}>
                    <Table.Column title={"Kategori İsmi"} dataIndex={"name"} key={"name"} />
                    {/* Delete butonu için bir kolon ekleyin */}
                    <Table.Column
                        title={"İşlemler"}
                        key={"actions"}
                        render={(text, record) => (
                            <Button
                                type='primary'
                                loading={deleteLoading}
                                className='bg-primary'
                                onClick={() => handleDelete(record.id)}
                            >
                                Sil
                            </Button>
                        )}
                    />
                </Table>
            </Col>
        </Row>
    );
};

export default AddCategory;