import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import storageService from "../services/storageService";
import AuthContext from "./AuthContext";
import { useDispatch } from "react-redux";
import { showNotification } from "../actions/notificationAction";

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [token, setToken] = useState(storageService.getToken() || "");
    const [refreshToken, setRefreshToken] = useState(storageService.getRefreshToken() || "");
    const handleLogout = () => {
        setToken("");
        setRefreshToken("");
        storageService.removeToken();
        storageService.removeRefreshToken();
        dispatch(showNotification({ type: 'success', message: 'Çıkış Başarılı' }));
        navigate("/");
    };
    const handleLogin = (token, refreshToken) => {
        if (token === undefined || refreshToken === undefined)
            dispatch(showNotification({ type: 'error', message: 'Giriş Başarısız', description: "E-posta veya şifre hatalı. Lütfen tekrar deneyin." }));
        setToken(token);
        setRefreshToken(refreshToken);
        storageService.addToken(token);
        storageService.addRefreshToken(refreshToken);
        dispatch(showNotification({ type: 'success', message: 'Giriş Başarılı' }));
        var returnPath = Object.values(location.state);
        navigate(returnPath[0]?.pathname || "/admin");
    };
    const value = {
        token,
        refreshToken,
        onLogout: handleLogout,
        onLogin: handleLogin,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;